// Importar las funciones necesarias de Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';

// Tu configuración de Firebase
const firebaseConfig = {
    apiKey: "AIzaSyCwhTnT2kV8XAgGQDJpY7BZmEPQXQjXSGM",
    authDomain: "gestion-tickets-ec742.firebaseapp.com",
    projectId: "gestion-tickets-ec742",
    storageBucket: "gestion-tickets-ec742.appspot.com",
    messagingSenderId: "61947717268",
    appId: "1:61947717268:web:d95410e8dbb2377b06f681",
    measurementId: "G-VPFKSP7L54"
};

// Inicializar Firebase
firebase.initializeApp(firebaseConfig);

// Inicializar Firestore
const db = firebase.firestore();

// Inicializar Firebase Storage
const storage = firebase.storage();

// Exportar las instancias de Firestore y Storage
export { db, storage };
